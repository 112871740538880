<template>
  <base-card>
    <template v-slot:header>
      <div class="card-header">
        <slot name="header">
          <h4 class="text-base font-medium">{{ title }}</h4>
          <slot name="actions" />
        </slot>
      </div>
    </template>
    <template v-slot:default>
      <div class="card-body">
        <slot></slot>
      </div>
    </template>
  </base-card>
</template>

<script>
  import BaseCard from './BaseCard.vue';

  export default {
    name: 'CardWithHeader',
    components: {
      BaseCard,
    },
    props: {
      title: {
        type: String,
      },
    },
  };
</script>

<style lang="scss" scoped>
  .new-ui {
    .card-header {
      @apply flex w-full items-center justify-between rounded-t-lg px-6 py-2;
    }
    .card-body {
      @apply px-8 py-6 text-card-body;
    }

    &.mode--light {
      .card-header {
        @apply bg-main-color-50;
      }
    }

    &.mode--dark {
      .card-header {
        @apply border-b border-divider;
      }
    }
  }
</style>
