<template>
  <div>
    <div class="my-12 flex items-center justify-between">
      <h1>Time-based Sales</h1>

      <new-button
        data-cy="create-sale-btn"
        @click="
          openNewTimeBasedSaleModal();
          $eventStore.timeBasedSales.createASale();
        "
      >
        Create a sale
      </new-button>
    </div>

    <new-alert
      data-cy="time-based-subscription-alert"
      class="my-3"
      v-if="userWeeklySalesLimit === 0"
    >
      You are currently on the {{ userPlan }} plan.<br />
      You can schedule sales, but they will not be published until you
      <router-link
        data-cy="time-based-upgrade-link"
        to="/billing"
        class="text-main-color-30 underline"
        >grab a {{ getPlanLabel('standard') }} Hypefury subscription</router-link
      >.
    </new-alert>
    <new-alert type="warning" class="my-3" v-else-if="shouldShowNextPlanAlert">
      <p>
        You have exceeded your weekly sales limit.<br />
        As a {{ userPlan }} plan user you are limited to {{ userWeeklySalesLimit }} sale(s) per
        week.
        <a
          href="javascript:;"
          class="text-main-color-30 underline"
          @click="openSalesLimitNextPlanPopUp"
          >Upgrade your plan</a
        >
        to be able to schedule more sales.
      </p>
    </new-alert>

    <div data-cy="sales-banner" class="card tweet dark-mode-bg p-5 md:flex md:justify-between">
      <p>Thanks to urgency, offers that are limited in time show great results.</p>
      <p>We recommend 2 hours long sales with the default reminders enabled.</p>
    </div>

    <div class="my-6 space-y-6">
      <card-with-header data-cy="sale-card-item" v-for="timeBasedSale in timeBasedSales" :key="timeBasedSale.id">
        <div slot="header" class="flex w-full items-center justify-between py-1">
          <div data-cy="sale-card-time" :set="(startingTime = timeData(timeBasedSale))">
            <p v-if="timeBasedSale.time.isAfter(momentNow)">
              Starts on {{ startingTime.day }} at {{ startingTime.time }}
            </p>
            <p v-else>
              Started on {{ startingTime.day }} at {{ startingTime.time }}
              <span v-if="timeBasedSale.timerData.hasEnded">(Ended)</span>
              <span v-else-if="timeBasedSale.isCancelled">(Canceled)</span>
            </p>
          </div>
          <new-drop-down data-cy="sale-card-menu" placement="left" buttonType="custom">
            <template #button>
              <inline-svg src="/img/icons/moreOptions3dot.svg" class="hover:text-gray-80" />
            </template>
            <template #default>
              <div class="flex flex-col items-center">
                <new-button
                  data-cy="dropdown-item-duplicate"
                  type="dropdown"
                  @click="duplicateSale(timeBasedSale)"
                >
                  <inline-svg src="/img/icons/copy.svg" class="h-5 w-5" />
                  <span>Duplicate Sale</span>
                </new-button>
                <new-button
                  v-if="
                    !timeBasedSale.isCancelled &&
                    !timeBasedSale.timerData.hasEnded &&
                    timeBasedSale.time.isBefore(momentNow)
                  "
                  @click="cancelSale(timeBasedSale)"
                  type="dropdown"
                >
                  <inline-svg
                    stroke="#f56565"
                    class="h-5 w-5 text-red-500"
                    src="/img/icons/delete.svg"
                  />
                  <span class="text-sm text-red-500">Cancel Sale</span>
                </new-button>
                <new-button
                  v-if="timeBasedSale.time.isAfter(momentNow)"
                  @click="deleteSale(timeBasedSale)"
                  type="dropdown"
                  data-cy="dropdown-item-delete"
                >
                  <inline-svg
                    stroke="#f56565"
                    class="h-5 w-5 text-red-500"
                    src="/img/icons/delete.svg"
                  />
                  <span class="text-sm text-red-500">Delete Sale</span>
                </new-button>
              </div>
            </template>
          </new-drop-down>
        </div>
        <template #default>
          <div
            class="row whitespace-pre-wrap break-words text-base"
            v-html="formatTweet(timeBasedSale.tweets[0].status)"
            dir="auto"
          />
          <queue-thumbnail :media="timeBasedSale.tweets[0].media" />
        </template>
      </card-with-header>
    </div>

    <upgrade-to-next-plan-pop-up
      :show="showNextPlanPopUp"
      :content="nextPlanPopUpContent"
      @close="showNextPlanPopUp = false"
      :nextPlan="nextPlan"
    />

    <div>
      <new-time-based-sale-modal
        v-if="isSaleModalVisible"
        @close="closeNewTimeBasedSaleModal"
        :saleToDuplicate="saleToDuplicate"
      />
    </div>
    <back-to-top-custom />
  </div>
</template>

<script>
  import BackToTopCustom from '@/views/Widgets/BackToTopCustom';
  import dao from '@/dao';
  import { mapGetters } from 'vuex';
  import moment from 'moment';
  import 'moment-timezone';
  import NewTimeBasedSaleModal from '@/views/Modals/NewTimeBasedSaleModal';
  import TimeFormatterMixin from '@/views/Mixins/TimeFormatterMixin';
  import SwalModalMixin from '@/views/Mixins/SwalModalMixin.vue';
  import { store } from '@/store';
  import { Thread } from '../../models/Thread';
  import { formatTweet } from '@/util/formatTweet';
  import QueueThumbnail from '@/views/QueueThumbnail';
  import CardWithHeader from '@/components/Cards/CardWithHeader.vue';
  import CustomerStatusMixin from '../Mixins/CustomerStatusMixin.vue';
  import controller from '../../controller';
  import UpgradeToNextPlanPopUp from '@/components/UpgradeToNextPlanPopUp.vue';
  import UpgradeToNextPlanMixin from '../Mixins/UpgradeToNextPlanMixin.vue';

  const fb = require('@/firebase');

  export default {
    name: 'time-based-sales',
    data() {
      return {
        isSaleModalVisible: false,
        timeBasedSales: [],
        saleToDuplicate: null,
        listOfSnapshotsUnsubscriptions: [],
        gumroadSalesCount: 0,
      };
    },
    components: {
      BackToTopCustom,
      NewTimeBasedSaleModal,
      QueueThumbnail,
      CardWithHeader,
      UpgradeToNextPlanPopUp,
    },
    computed: {
      shouldShowNextPlanAlert() {
        const nextPlan = this.getNextPlan('weeklySales');
        // Trial is the last plan, so if the user is on trial getNextPlan won't work as intended
        const isThereANextPlan = this.userProfile.customerStatus !== 'trial' || nextPlan;
        return (
          isThereANextPlan &&
          this.userWeeklySalesLimit !== Infinity &&
          this.currentWeekSalesCount >= this.userWeeklySalesLimit
        );
      },
      currentWeekSalesCount() {
        const startOfCurrentWeek = moment().startOf('week');
        const currentWeekTimeBasedSales = this.timeBasedSales.filter((timeBasedSale) => {
          const saleStartTime = moment(timeBasedSale.created_at.toDate());
          return saleStartTime.isSameOrAfter(startOfCurrentWeek);
        });
        return this.gumroadSalesCount + currentWeekTimeBasedSales.length;
      },
      userWeeklySalesLimit() {
        return this.PLANS[this.userProfile.customerStatus].limits.weeklySales;
      },
      momentNow() {
        return moment.tz(this.userProfile.timezone).toDate();
      },
      ...mapGetters({ currentUser: 'getCurrentUser', userProfile: 'getUserProfile' }),
    },
    methods: {
      formatTweet(tweet) {
        return formatTweet(tweet).replace(/&amp;/g, '&');
      },
      cancelSale(timeBasedSale) {
        this.swalModal({
          title: 'Cancel sale',
          text: `Are you sure you want to end this sale?`,
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, end this sale',
          preConfirm: () => {
            this.$eventStore.timeBasedSales.cancelSale();
            dao.integrations.cancelTimeBasedSale(timeBasedSale.id).catch(() => {
              this.swalModal({
                title: 'Sorry',
                text: `An error has occurred while ending the sale.`,
                type: 'warning',
              });
            });
          },
        });
      },
      duplicateSale(timeBasedSale) {
        this.$eventStore.timeBasedSales.duplicateSale();
        this.saleToDuplicate = timeBasedSale;
        this.openNewTimeBasedSaleModal();
      },
      closeNewTimeBasedSaleModal() {
        this.isSaleModalVisible = false;
      },
      deleteSale(timeBasedSale) {
        if (timeBasedSale.time.isBefore(new Date())) return;
        this.swalModal({
          title: 'Delete sale',
          text: `Are you sure you want to delete this sale?`,
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete this sale',
          preConfirm: () => {
            this.$eventStore.timeBasedSales.deleteSale();
            dao.integrations.deleteTimeBasedSale(timeBasedSale.id).catch(() => {
              this.swalModal({
                title: 'Sorry',
                text: `An error has occurred while deleting the sale.`,
                type: 'warning',
              });
            });
          },
        });
      },
      openNewTimeBasedSaleModal() {
        this.isSaleModalVisible = true;
      },
      // This is pretty much a duplicate of the postingTimeData from models/Post.js
      timeData(timeBasedSale) {
        const saleTime = moment.tz(timeBasedSale.time, this.userProfile.timezone);

        const time = this.formatTimeWithUserLocale(saleTime);
        let day = 'today';

        const midnightOfToday = moment().startOf('day');
        const midnightOfTime = saleTime.startOf('day');
        if (midnightOfToday.diff(midnightOfTime, 'day') === 0) {
          day = 'today';
        } else if (midnightOfToday.diff(midnightOfTime, 'day') === -1) {
          day = 'tomorrow';
        } else if (midnightOfToday.diff(midnightOfTime, 'day') === 1) {
          day = 'yesterday';
        } else {
          day = saleTime.format('dddd');
          day += ' ' + saleTime.format('MMMM Do');
        }

        return { day, time };
      },
      updateTimeBasedSales() {
        const unsubscribe = fb.threadsCollection
          .where('source', '==', 'time-based-sale')
          .where('user', '==', fb.usersCollection.doc(this.userProfile.uid))
          .orderBy('time', 'desc')
          .onSnapshot((doc) => {
            this.timeBasedSales = doc.docs.map((d) => Thread.buildFromFirestore(d));
          });

        this.listOfSnapshotsUnsubscriptions.push(unsubscribe);
        store.dispatch('addFirestoreListener', unsubscribe);
      },
    },
    mixins: [TimeFormatterMixin, SwalModalMixin, CustomerStatusMixin, UpgradeToNextPlanMixin],
    async mounted() {
      this.$eventStore.timeBasedSales.visitPage();
      this.updateTimeBasedSales();
      // We only need to fetch the time based sales count if the user has a limit;
      if (this.userWeeklySalesLimit >= 0 && this.userWeeklySalesLimit !== Infinity) {
        this.gumroadSalesCount = (
          await controller.getCurrentWeekGumroadSalesCount(this.currentUser, this.userProfile.uid)
        ).data;
      }
    },
    beforeDestroy() {
      this.listOfSnapshotsUnsubscriptions.forEach((unsubscribe) => {
        if (!unsubscribe) return;
        unsubscribe();
      });
    },
  };
</script>

<style>
  @supports (padding: max(0px)) {
    form {
      padding-bottom: env(safe-area-inset-bottom) !important;
    }
  }
</style>
