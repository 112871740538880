<template>
  <div :class="['card', { bordered: bordered, 'light-bordered': lightBordered }]">
    <slot name="header" />
    <slot name="body" />
    <slot name="extra" />
    <slot />
  </div>
</template>

<script>
  export default {
    name: 'BaseCard',
    props: {
      bordered: {
        type: Boolean,
        default: true,
      },
      lightBordered: {
        type: Boolean,
        default: false,
      },
    },
  };
</script>

<style lang="scss" scoped>
  body {
    &.new-ui {
      .card {
        @apply block rounded-lg;
      }
      .light-bordered {
        @apply border border-ds-outline-secondary;
      }
      &.mode--light {
        .card {
          @apply bg-white;
          &.bordered {
            @apply border border-main-color-40;
          }
        }
      }
      &.mode--dark {
        .card {
          @apply bg-dark-mode-70;

          &.bordered {
            @apply border border-ds-outline-primary;
          }
        }
      }
    }
  }
</style>
