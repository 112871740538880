<template>
  <base-modal
    @close="close"
    :loading="submitting"
    title="Create a sale"
    dataCy="time-based-sale-modal"
  >
    <div role="form" class="needs-validation max-w-610 p-6">
      <div class="space-y-6">
        <div class="space-y-3">
          <div class="font-weight-medium">Add your sales tweet</div>
          <div class="relative rounded-md border-2 border-input-border px-3 py-1">
            <div class="tweet-container" ref="tweet_container">
              <textarea
                data-cy="status-textarea"
                class="form-control simple"
                ref="status"
                rows="3"
                v-model="tweet.status"
                dir="auto"
                placeholder="Don't forget to add a link to your product"
                id="status"
              ></textarea>
            </div>
            <portal-target
              name="time-based-sales-media"
              class="thread-preview focus--hide space-y-2 p-2 pb-0"
              multiple
            >
            </portal-target>
            <div class="thread-actions item-center flex p-1">
              <div class="focus--hide flex items-center">
                <tooltip data-cy="upload-media" content="Upload Media">
                  <upload-container
                    :pastedFiles="pastedFiles"
                    :tweet="tweet"
                    portalTargetName="time-based-sales-media"
                    :canAddMedia="canAddMedia"
                    :canAddMoreVideos="canAddMoreVideos"
                    :clearMediaSelectionAtIndexParent="clearMediaSelectionAtIndex"
                    :uploadingMedia="uploadingMedia"
                  />
                </tooltip>
              </div>
              <div class="ml-auto flex items-center space-x-1">
                <character-counter
                  :maximumCharacters="tweetCharactersLimit"
                  :textLength="tweetLength"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="space-y-3">
          <div class="grid grid-cols-2 items-center justify-center gap-y-3">
            <label class="font-weight-medium text-primary">Sale start time</label>
            <date-picker
              v-if="Boolean(time)"
              id="start-time"
              v-model="time"
              data-cy="start-time-input"
              mode="dateTime"
              :min-date="minDateForInput"
              :max-date="maxDateForInput"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <base-text-input
                  :value="inputValue"
                  v-on="inputEvents"
                  :error="getTimeError(false, time)"
                >
                  <inline-svg
                    slot="icon"
                    src="/img/icons/date-icon.svg"
                    class="h-6 w-6 fill-current"
                  />
                </base-text-input>
              </template>
            </date-picker>
            <label class="font-weight-medium text-primary">Sale duration</label>
            <base-select
              data-cy="duration-select"
              v-model="saleDuration"
              :options="saleDurationOptions"
              :error="getTimeError(true, saleDuration)"
              labelAttribute="label"
              valueAttribute="value"
              placeholder="Select an Option"
            />
          </div>
        </div>

        <div class="space-y-4">
          <div class="space-y-1">
            <div class="font-weight-medium text-primary">End of sale reminders</div>
            <div
              data-cy="reminder-text"
              class="text-muted"
              v-if="lessThanTwoHoursRepliesInMinutes.length > 0"
            >
              Hypefury will tweet
              {{ selectedLessThanTwoHoursRepliesOptions }}
              {{
                selectedLessThanTwoHoursRepliesOptions.length === 1 &&
                selectedLessThanTwoHoursRepliesOptions[0] === '1'
                  ? 'minute'
                  : 'minutes'
              }}
              before the sale ends.
            </div>
            <div data-cy="reminder-text" class="text-muted" v-else>
              Remind people before your sale ends.
            </div>
          </div>
          <div data-cy="reminder-time-item" class="flex flex-wrap gap-2">
            <new-base-checkbox
              v-for="(name, val) in lessThanTwoHoursRepliesOptions"
              :key="val"
              :inputValue="val"
              :label="name"
              v-model="lessThanTwoHoursRepliesInMinutes"
              type="tag"
            />
          </div>
        </div>

        <div class="space-y-4" v-if="saleDuration > 2">
          <div class="space-y-1">
            <div data-cy="reminder-text-2" class="text-muted">
              If the time left is above 2 hours, Hypefury will tweet
              {{ selectedTwoToFourHoursReplyOption }} before the sale ends.
            </div>
          </div>

          <div data-cy="reminder-time-item" class="flex flex-wrap space-x-2">
            <new-base-radio
              v-for="(name, val) in twoToFourHoursRepliesOptions"
              :key="val"
              name="twoToFourHoursRepliesOptions"
              :value="parseInt(val)"
              v-model="twoToFourHoursReplyDelayInHours"
              size="sm"
              type="tag"
            >
              {{ name }}
            </new-base-radio>
          </div>
        </div>
      </div>

      <new-button
        class="ml-auto mt-12"
        @click="saveSale"
        :disabled="!canFormBeSubmitted()"
        data-cy="add-sale-button"
      >
        Add Sale
      </new-button>
    </div>
  </base-modal>
</template>

<script>
  import { countTweetLength } from '@/../functions/src/util/countTweetLength';
  import { mapGetters } from 'vuex';
  import moment from 'moment';
  import 'moment-timezone';
  import ThreadMixin from '../Mixins/ThreadMixin';
  import { Thread } from '../../models/Thread';
  import lodash from 'lodash';
  import SwalModalMixin from '../Mixins/SwalModalMixin.vue';
  import UploadContainerMixin from '../Mixins/UploadContainerMixin.vue';
  import UploadContainer from '../../components/UploadContainer';
  import DatePicker from 'v-calendar/lib/components/date-picker.umd';
  import CharacterCounter from '../../components/CharacterCounter.vue';
  import { EventBus } from '@/event-bus';
  import TweetCharactersLimitMixin from '../Mixins/TweetCharactersLimitMixin.vue';

  const config = require('../../config');

  export default {
    components: {
      UploadContainer,
      DatePicker,
      CharacterCounter,
    },
    created() {
      this.setTime();
      this.onModalVisible();
    },
    mounted() {
      EventBus.$on('set-uploading-media', (i) => {
        this.uploadingMedia = [...this.uploadingMedia, i];
      });

      EventBus.$on('unset-uploading-media', (i) => {
        this.uploadingMedia = [...this.uploadingMedia.filter((idx) => idx !== i)];
      });
    },
    computed: {
      ...mapGetters({ currentUser: 'getCurrentUser', userProfile: 'getUserProfile' }),
      maxDateForInput() {
        return moment.tz(this.timezone).add(1, 'month').format('YYYY-MM-DDTHH:mm:ss');
      },
      minDateForInput() {
        return moment
          .tz(this.timezone)
          .add(5, 'minute')
          .startOf('minute')
          .format('YYYY-MM-DDTHH:mm:ss');
      },
      selectedLessThanTwoHoursRepliesOptions() {
        const sortedOptions = this.lessThanTwoHoursRepliesInMinutes
          .map((option) => Number(option))
          .sort((a, b) => b - a);

        return sortedOptions.length < 2
          ? sortedOptions.join()
          : `${sortedOptions
              .filter((option) => option !== lodash.last(sortedOptions))
              .join(', ')} and ${lodash.last(sortedOptions)}`;
      },
      selectedTwoToFourHoursReplyOption() {
        return this.twoToFourHoursRepliesOptions[this.twoToFourHoursReplyDelayInHours].toLowerCase()
      },
      canAddMedia() {
        return (
          this.tweet.mediaFile.length === 0 ||
          (this.tweet.mediaFile.length < 4 &&
            !['video', 'gif'].includes(this.tweet.mediaFile[0].type))
        );
      },
      canAddMoreVideos() {
        return (
          this.tweet.mediaFile.length === 0 ||
          this.tweet.mediaFile.filter((media) => media.type.includes('video')).length === 0
        );
      },
      saleDurationOptions() {
        return [
          { value: 1, label: '1 hour' },
          { value: 2, label: '2 hours' },
          { value: 4, label: '4 hours' },
        ];
      },
      tweetLength() {
        return countTweetLength(this.tweet.status);
      },
    },
    data() {
      return this.initialState();
    },
    methods: {
      canFormBeSubmitted() {
        return (
          countTweetLength(this.tweet.status) > 0 &&
          countTweetLength(this.tweet.status) <= this.tweetCharactersLimit &&
          this.isTimeValid(this.time) &&
          this.uploadingMedia.length === 0
        );
      },
      initialState() {
        return {
          lessThanTwoHoursRepliesOptions: {
            1: '1m',
            2: '2m',
            5: '5m',
            15: '15m',
            30: '30m',
            45: '45m',
            60: '60m',
            90: '90m',
          },
          twoToFourHoursRepliesOptions: { 1: 'Every hour', 2: 'Every 2 hours' },
          lessThanTwoHoursRepliesInMinutes: [5, 15, 30, 45, 60, 90],
          twoToFourHoursReplyDelayInHours: 2,
          saleDuration: 2,
          time: null,
          tweet: this.emptyTweet(0),
          submitting: false,
          pastedFiles: [],
          mediaFiles: [],
          uploadingMedia: [],
        };
      },
      async saveSale() {
        if (!this.canFormBeSubmitted()) {
          this.$forceUpdate();
          return null;
        }

        const repliesDelays = {
          lessThanTwoHoursRepliesInMinutes: this.lessThanTwoHoursRepliesInMinutes.map((time) =>
            parseInt(time)
          ),
          twoToFourHoursReplyDelayInHours: parseInt(this.twoToFourHoursReplyDelayInHours),
        };

        const timerData = {
          repliesDelays,
          hasEnded: false,
          wasCanceled: false,
          saleDuration: parseInt(this.saleDuration),
        };

        const thread = Thread.newThread({
          time: moment.tz(this.time, this.userProfile.timezone).toDate(),
          tweets: [this.tweet],
          user: this.userProfile.uid,
          timerData,
          source: 'time-based-sale'
        });

        this.submitting = true;

        try {

          if (thread.tweets[0].mediaFile.length) {
            thread.tweets[0].media.forEach((media) => {
              delete media.url;
            });
          }

          if (Array.isArray(thread.tweets[0].mediaFile) && thread.tweets[0].mediaFile.length) {
            delete thread.tweets[0].mediaFile;
            delete thread.tweets[0].fileList;
          }

          await thread.saveToFirestore(
            this.currentUser,
            this.userProfile.uid,
            this.userProfile.timezone,
            this.$eventStore
          );
          this.$notify({ type: 'success', message: 'Your sale has been scheduled successfully!' });
        } catch (error) {
          this.swalModal({
            title: 'Error',
            html: `An error has occurred while trying to schedule a sale.`,
            type: 'warning',
          });
        } finally {
          Object.assign(this.$data, this.initialState());
          this.close();
          this.submitting = false;
        }

      },
      updateValuesFromDuplicateSale() {
        this.twoToFourHoursReplyDelayInHours = lodash.get(
          this.saleToDuplicate,
          'timerData.repliesDelays.twoToFourHoursReplyDelayInHours',
          2
        );
        this.lessThanTwoHoursRepliesInMinutes = lodash.get(
          this.saleToDuplicate,
          'timerData.repliesDelays.lessThanTwoHoursRepliesInMinutes',
          [1, 2, 5, 15, 30, 45, 60, 90]
        );
        this.saleDuration = lodash.get(this.saleToDuplicate, 'timerData.saleDuration', 2);
        this.tweet = {...this.emptyTweet(0), status: this.saleToDuplicate.tweets[0].status, media: this.saleToDuplicate.tweets[0].media };

        if (this.saleToDuplicate.tweets[0].media) {
          this.tweet.mediaFile = [];
          this.saleToDuplicate.tweets[0].media.forEach((media, i) => {
            const mediaName = media.name;
            const mediaType = media.type;
            const mediaAltText = media.altText;
            this.tweet.mediaFile[i] = {
              name: mediaName,
              type: this.getMediaType(mediaType),
              url: config.buildStorageMediaURL(mediaName),
              size: media.size,
              altText: mediaAltText,
            };
          });
          this.tweet.fileList = [];
        }
      },
      setTime: function () {
        this.time = moment
          .tz(this.userProfile.timezone)
          .add(5, 'minute')
          .startOf('minute')
          .format('YYYY-MM-DDTHH:mm:ss');
      },
      clearMediaSelectionAtIndex(i) {
        this.tweet.mediaFile.splice(i, 1);
        this.tweet.fileList.splice(i, 1);
        this.$forceUpdate();
      },
      onModalVisible(show = true) {
        if (show) {
          this.setTime();

          if (this.saleToDuplicate) {
            this.updateValuesFromDuplicateSale();
          }
        }
      }
    },
    watch: {
      show(val) {
        this.onModalVisible(val);
      },
      saleDuration(val) {
        if (parseInt(val) === 1) {
          this.lessThanTwoHoursRepliesOptions = lodash.omit(this.lessThanTwoHoursRepliesOptions, [
            60,
            90,
          ]);
          this.lessThanTwoHoursRepliesInMinutes = this.lessThanTwoHoursRepliesInMinutes.filter(
            (time) => time < 60
          );
        } else {
          this.lessThanTwoHoursRepliesOptions = {
            ...this.lessThanTwoHoursRepliesOptions,
            60: '60m',
            90: '90m',
          };
        }
      },
    },
    name: 'new-time-based-sale-modal',
    props: {
      show: Boolean,
      saleToDuplicate: {
        type: Object,
        default: null,
      },
    },
    mixins: [ThreadMixin, SwalModalMixin, UploadContainerMixin, TweetCharactersLimitMixin],
  };
</script>

<style>
  @supports (padding: max(0px)) {
    form {
      padding-bottom: env(safe-area-inset-bottom) !important;
    }
  }
</style>
